import React, { useEffect, useState } from 'react';
import './App.css';
import logo from './img/logo.png';
import axios from 'axios';

const linkBase = 'https://release.memsql.com/production/index/';
const softwareInfo = {
  toolbox: { link: 'memsqltoolbox/', name: 'Toolbox' },
  studio: { link: 'memsqlstudio/', name: 'Studio' },
  client: { link: 'memsqlclient/', name: 'Client' },
  server: { link: 'memsqlserver/', name: 'Server' },
};

export default function App() {
  const [selectedVersions, setSelectedVersions] = useState({});
  const [status, setStatus] = useState('Initializing...');
  const [data, setData] = useState({});
  const [opsOpen, setOpsOpen] = useState(false);
  const [opsServVer, setOpsServVer] = useState('6.8.24');

  useEffect(() => {
    setStatus('Fetching software versions...');
    const fetchData = async () => {
      try {
        const results = await Promise.all(
          Object.entries(softwareInfo).map(async ([software, info]) => {
            setStatus(`Fetching ${software} versions...`);
            const versionsResponse = await axios.get(`${linkBase}${info.link}versions.json`);
            const versions = versionsResponse.data.versions;
            const latest = versions[0];
            setStatus(`Fetching ${software} latest JSON...`);
            const jsonResponse = await axios.get(`${linkBase}${info.link}${latest}.json`);
            const json = jsonResponse.data;
            return { software, versions, json };
          })
        );

        setData(
          results.reduce((acc, { software, versions, json }) => {
            acc[software] = { versions, json };
            return acc;
          }, {})
        );

        setSelectedVersions(
          results.reduce((acc, { software, versions }) => {
            acc[software] = versions[0];
            return acc;
          }, {})
        );

        setStatus('Fetched initial data.');
      } catch (error) {
        console.error('Error fetching data:', error);
        setStatus('Error initializing');
      }
    };

    fetchData();
  }, []);

  const handleVersionChange = async (software, version) => {
    if (version === selectedVersions[software]) {
      return;
    }

    setStatus(`Fetching ${software} data for version ${version}...`);
    try {
      const json = (await axios.get(`${linkBase}${softwareInfo[software].link}${version}.json`)).data;
      setData((prevData) => ({
        ...prevData,
        [software]: { ...prevData[software], json },
      }));
      setSelectedVersions((prevVersions) => ({ ...prevVersions, [software]: version }));
      setStatus(`Fetched ${software} data for version ${version}.`);
    } catch (error) {
      console.error('Error fetching data:', error);
      setStatus('Error fetching data');
    }
  };

  const handleDownload = async (link) => {
    const fileName = link.split('/').pop();
    setStatus(`Downloading ${fileName}...`);
    const a = document.createElement('a');
    a.href = link;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setStatus(`Downloaded ${fileName}.`);
  };

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link);
    setStatus('Link copied to clipboard!');
  };

  return (
    <div className="app">
      <header className="header">
        <img src={logo} alt="SingleStore Logo" className="logo" />
        <h1 className="header-title">Download</h1>
      </header>

      <main className="main">
        <div className="status">{status}</div>

        <div className="software-grid">
          {Object.entries(data).map(([software, { versions, json }]) => (
            <div key={software} className="software-card">
              <div className="software-header">
                <h2 className="software-title">SingleStore {softwareInfo[software].name}</h2>
              </div>
              <div className="software-content">
                <div className="version-select">
                  <label htmlFor={`${software}-version`}>Version:</label>
                  <select
                    id={`${software}-version`}
                    value={selectedVersions[software] || ''}
                    onChange={(e) => handleVersionChange(software, e.target.value)}
                  >
                    {versions.map((version) => (
                      <option key={version} value={version}>
                        {version}
                      </option>
                    ))}
                  </select>
                </div>
                {json?.packages && (
                  <div className="packages">
                    {Object.entries(json.packages).map(([packageName, packageData]) => (
                      <div key={packageName} className="package">
                        <div className="package-header">
                          <h3 className="package-title">{packageName}</h3>
                          <a
                            href={`https://release.memsql.com/${packageData.Path}`}
                            onClick={() => handleDownload(`https://release.memsql.com/${packageData.Path}`)}
                            className="download-button"
                          >
                            Download
                          </a>
                        </div>
                        <div className="package-actions">
                          <button className="copy-link-button" onClick={() => handleCopyLink(`https://release.memsql.com/${packageData.Path}`)}>
                            Copy Link
                          </button>
                          <p className="sha">{packageData.Sha256Sum}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        <button className="ops-button" onClick={() => setOpsOpen(!opsOpen)}>
          I need Ops...
        </button>

        {opsOpen && (
          <div className="ops-info">
            <h2>MemSQL (SingleStore) Ops</h2>
            <p>
              Note: Ops is EOL and incompatible with server versions 7.5+. This tool will not check the
              validity of the server binary link (it will just form it).
            </p>
            <div className="ops-download">
              <h4>Ops 7.0.7 (Latest)</h4>
              <a href="http://download.memsql.com/memsql-ops-7.0.7/memsql-ops-7.0.7.tar.gz" className="download-button">
                Download
              </a>
              <h4>Ops Server Binaries</h4>
              <a
                href={`https://download.memsql.com/releases/version/${opsServVer}/memsqlbin_amd64.tar.gz`}
                className="download-button"
              >
                Download
              </a>
              <div className="ops-version-input">
                <label htmlFor="ops-version">Enter Server Version:</label>
                <input
                  id="ops-version"
                  type="text"
                  value={opsServVer}
                  onChange={(e) => setOpsServVer(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}